import React, { useEffect, useState } from "react";

import { gql, useMutation } from "@apollo/client";
import { navigate } from "gatsby";

import { getRedirectUrl, removeRedirectUrl } from "@/account/redirect";
import { isPasswordValid } from "@/account/utils";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import Modal from "@/components/common/modal";
import PasswordInput from "@/components/common/passwordInput";
import { UrlParamKeyEnum } from "@/components/constants";
import { isBrowser } from "@/utils/env";
import { getUrlParameterByKey } from "@/utils/urlUtils";
import { Button, ButtonColorEnum, ButtonSizeEnum } from "@components/common/button";

import "./styles.scss";

const SET_NEW_PASSWORD_MUTATION = gql`
  mutation setNewPassword($token: String!, $newPassword: String!) {
    setNewPassword(token: $token, newPassword: $newPassword)
  }
`;

// todo: check if used and delete if not
const SetNewPassword = () => {
  const [setNewPassword, {
    data: setNewPasswordData,
    loading: setNewPasswordLoading,
    error: setNewPasswordError,
  }] = useMutation(SET_NEW_PASSWORD_MUTATION);

  const handleClose = () => {
    const redirectUrl = getRedirectUrl() ?? "/";
    removeRedirectUrl();
    navigate(redirectUrl, { replace: true });
  };

  const [password, setPassword] = useState<string>("");
  const [repeatedPassword, setRepeatedPassword] = useState<string>("");
  const [isPasswordTouched, setIsPasswordTouched] = useState<boolean>(false);
  const [isRepeatedPasswordTouched, setIsRepeatedPasswordTouched] = useState<boolean>(false);

  const handleSubmit = () => {
    setNewPassword({ variables: { token: getUrlParameterByKey("token") ?? "", newPassword: password } });
  };

  useEffect(() => {
    if (setNewPasswordData && !setNewPasswordLoading && !setNewPasswordError) {
      // todo: ask Alexandr if tokens change
      // setAccessToken(loginData.login.tokens.accessToken);
      // setRefreshToken(loginData.login.tokens.refreshToken);
      // setIsShown(true); // todo: check if everything works without this line
    } else if (setNewPasswordError) {
      console.log("setNewPasswordError", setNewPasswordError);
    }
  }, [setNewPasswordData, setNewPasswordLoading, setNewPasswordError]);

  if (!isBrowser()) {
    return null;
  }

  return (
    <>
      <div className="reset-password__container">
        <h2 className="reset-password__header">Изменение пароля</h2>
        <form
          className="reset-password__form"
          onSubmit={(e) =>
            e.preventDefault()}
        >
          <div className="reset-password__field-group">
            <div className="reset-password__label">
              <label htmlFor="password">Новый пароль</label>
            </div>
            <div className="reset-password__field">
              <PasswordInput
                placeholder="Не менее 6 символов"
                className={`middle reset-password__input${
                  isPasswordTouched && !isPasswordValid(password, true) ? " error" : ""
                }`}
                name="password"
                id="password"
                aria-invalid="true"
                value={password}
                onChange={(e: any) =>
                  setPassword(e.target.value)}
                onBlur={() =>
                  setIsPasswordTouched(true)}
                disabled={setNewPasswordLoading}
              />
            </div>
            {isPasswordTouched && !password && (
            <div className="reset-password__error" role="alert">
              Укажите пароль. Не менее 6 символов
            </div>
            )}
            {isPasswordTouched && password && password.length < 6 && (
            <div className="reset-password__error" role="alert">
              Слишком короткий пароль. Он должен содержать не&nbsp;менее 6&nbsp;символов
            </div>
            )}
          </div>
          <div className="reset-password__field-group">
            <div className="reset-password__label">
              <label htmlFor="repeated-password">Подтвердите новый пароль</label>
            </div>
            <div className="reset-password__field">
              <PasswordInput
                placeholder="Не менее 6 символов"
                className={`middle reset-password__input${
                  isRepeatedPasswordTouched && repeatedPassword !== password ? " error" : ""
                }`}
                name="repeated-password"
                id="repeated-password"
                aria-invalid="true"
                value={repeatedPassword}
                onChange={(e: any) =>
                  setRepeatedPassword(e.target.value)}
                onBlur={() =>
                  setIsRepeatedPasswordTouched(true)}
                disabled={setNewPasswordLoading}
              />
            </div>
            {isRepeatedPasswordTouched && !repeatedPassword && (
            <div className="reset-password__error" role="alert">
              Повторите пароль
            </div>
            )}
            {isRepeatedPasswordTouched && repeatedPassword && repeatedPassword !== password && (
            <div className="reset-password__error" role="alert">
              Пароли должны совпадать
            </div>
            )}
          </div>
          <Button
            className="reset-password__submit"
            type="submit"
            text="Изменить пароль"
            size={ButtonSizeEnum.Middle}
            color={ButtonColorEnum.Dark}
            onClick={handleSubmit}
            disabled={!isPasswordValid(password, true) || repeatedPassword !== password}
          />
        </form>
      </div>
      <Modal urlParamKey={UrlParamKeyEnum.EmailConfirmation} formCloseCallback={handleClose}>
        <div className="reset-password-success">
          <div className="reset-password-success__main">
            <div className="reset-password-success__circle">
              <Icon type={IconTypeEnum.Tick} size={IconSizeEnum.Size24} />
            </div>
            <h2 className="reset-password-success__header">
              Ваш пароль
              <br />
              успешно изменён
            </h2>
          </div>
          <Button
            className="reset-password-success__close"
            type="submit"
            text="Готово"
            size={ButtonSizeEnum.Middle}
            color={ButtonColorEnum.Light}
            onClick={handleClose}
          />
        </div>
      </Modal>
    </>
  );
};

export default SetNewPassword;
